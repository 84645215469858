class Modal {
    constructor(context, options) {
        this.context = context;
        
        this.overlay = this.context.querySelector(".js-modal-overlay");
        this.window = this.context.querySelector(".js-modal-window");
        this.closeBtn = this.context.querySelector(".js-modal-close");
        
        this.isClosed = !this.context.classList.contains("o-modal_closed");
        
        this.closeBtn.addEventListener("click", this.closeBtnClickHandler.bind(this))
    }
    
    open() {
        this.context.classList.remove("o-modal_closed");
        this.isClosed = false;
    }
    
    close() {
        this.context.classList.add("o-modal_closed");
        this.isClosed = true;
    }

    closeBtnClickHandler() {
        this.close();
    }
}