class Point {
    
}

class Map {
    constructor(context) {
        this.YANDEX_ENGINE = 1;
        this.GOOGLE_ENGINE = 2;

        this.engine = this.YANDEX_ENGINE;
        
        this.context = context;
        this.element = this.context.querySelector(".js-map-instance");
        this.modal = new Modal(this.context.querySelector(".js-map-modal"));
        this.pointTitle = this.context.querySelector(".js-point-title");
        this.pointDescription = this.context.querySelector(".js-point-description");
        this.pointPhoto = this.context.querySelector(".js-point-photo");
        
        this.center = {
            lat: 0,
            lng: 0
        };
        this.zoom = 8;
        this.scroll = true;
        this.isInited = false;
        this.markers = [];
    }
    
    init() {

        switch (this.engine) {
            case this.YANDEX_ENGINE:
                this._initYandexEngine();
                return;

            case this.GOOGLE_ENGINE:
            default:
                this._initGoogleEngine();
                return;
        }
    }
    
    _initYandexEngine() {
        this._yMarkerOpts = {
            iconLayout: "default#image",
            iconImageHref: "/css/images/pin-blue.png",
            iconImageSize: [50, 75],
            iconImageOffset: [-25, -75]
        };
        
        this.map = new ymaps.Map(this.element, {
            center: [this.center.lat, this.center.lng],
            zoom: this.zoom
        }, {
            searchControlProvider: 'yandex#search'
        });

        this.clusterer = new ymaps.Clusterer({
            clusterIcons: [
                {
                    href: "/css/images/pin-blue.png",
                    size: [50, 75],
                    offset: [-25, -50]
                }
            ],
            clusterIconContentLayout: ymaps.templateLayoutFactory.createClass("<div style=\"color: #333; font-weight: bold; font-family: sans-serif\">{{ properties.geoObjects.length }}</div>")
        });
        this.map.geoObjects.add(this.clusterer);
        
        this.isInited = true;
    }

    _initGoogleEngine() {
        this.pinBlueIcon = {
            url: "/css/images/pin-blue.png",
            size: new google.maps.Size(50, 75),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 75)
        };

        this.pinDarkIcon = {
            url: "/css/images/pin-dark.png",
            size: new google.maps.Size(50, 75),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 75)
        };
        
        this.map = new google.maps.Map(this.element, {
            center: this.center,
            scrollwheel: this.scroll,
            zoom: this.zoom
        });
        
        this.isInited = true;
    }
    
    addPoint(point) {

        let pushed = null;
        
        switch(this.engine) {
                
            case this.YANDEX_ENGINE:
                pushed = this.markers.push(new ymaps.Placemark([point.position.lat, point.position.lng], {
                    hintContent: point.title
                }, this._yMarkerOpts));

                this.clusterer.add(this.markers[pushed - 1]);
                //this.map.geoObjects.add(this.markers[pushed - 1]);
                this.markers[pushed - 1].events.add("click", point.clickHandler.bind(point));
                return;
                
            case this.GOOGLE_ENGINE:
                pushed = this.markers.push(new google.maps.Marker({
                    position: point.position,
                    map: this.map,
                    title: point.title,
                    animation: google.maps.Animation.DROP,
                    icon: this.pinBlueIcon
                }));

                this.markers[pushed - 1].addListener("click", point.clickHandler.bind(point));
                return;
        }
    }
    
    setCenter(center) {
        this.center = center;

        if(this.isInited) {
            this.map.setCenter(this.center);
        }
    }
    
    setZoom(zoom) {
        this.zoom = zoom;
        
        if(this.isInited) {
            this.map.setZoom(this.zoom);
        }
    }

    displayPopup(point) {
        this.pointTitle.innerHTML = point.title;
        this.pointDescription.innerHTML = point.description;
        this.pointPhoto.src = point.photo;
        this.modal.open();
    }
}